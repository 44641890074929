<template>
  <div class="footer position-relative " :class="locale">
    <div class="footer__body" :class="locale">
      <div class="row d-none d-md-flex ">
        <div class="col-6 d-block d-lg-none">
          <Contact />
        </div>
        <div class="col-xl-4 col-lg-4 col-6 d-none d-lg-block">
          <FooterSection1 />
        </div>
        <div class="col-xl-5 col-lg-4  d-none d-lg-block">
          <FooterSection2 />
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <SocialSection />
        </div>
        <p
          class="footer__copyright d-block d-lg-none text-center w-100"
          :class="locale"
        >
          {{ $t("Copyrights.text1") }}
          <span class="text-secondary ml-1">{{ $t("Copyrights.text2") }}</span
          >{{ $t("Copyrights.text3") }}
        </p>
      </div>
      <FooterMobileContent />
    </div>
    <span
      class="footer__gotop cursor-pointer"
      @click="$emit('onClick')"
      :class="locale"
    >
      <img :src="require('../../assets/images/home/footer/top.svg')" alt="" />
    </span>
  </div>
</template>

<script>
import FooterSection1 from "./Section1.vue";
import FooterSection2 from "./Section2.vue";
import SocialSection from "./SocialSection.vue";
import Contact from "./Contact.vue";
import FooterMobileContent from "./FooterMobileContent.vue";

export default {
  components: {
    FooterSection1,
    FooterSection2,
    SocialSection,
    Contact,
    FooterMobileContent,
  },
  methods: {},
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
