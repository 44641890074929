<template>
  <section class="footer__section footersection1" :class="locale">
    <div class="row mx-0">
      <div class="col-6 px-0">
        <SectionContent
          :heading="c1Heading"
          :listitems="c1Items"
          :dotClass="`footer__sectionContent__dot ${locale}`"
        />
      </div>
      <div class="col-6 px-0">
        <SectionContent :heading="c2Heading" :listitems="c2Items" />
      </div>
      <div class="col-12 px-0 footer__space" :class="locale">
        <p class="footer__copyright" :class="locale">
          {{ $t("Copyrights.text1") }}
          <span class="text-secondary ml-1">{{ $t("Copyrights.text2") }}</span
          >{{ $t("Copyrights.text3") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import SectionContent from "./SectionContent.vue";
export default {
  components: {
    SectionContent,
  },
  data() {
    return {
      c1Heading: "Notre unicité ",
      c1Items: [
        { name: "Un parcours unique", link: "/#uniciteSection2" },
        { name: "Une composition unique", link: "/#uniciteSection3" },
        { name: "Un goût unique", link: "/#uniciteSection4" },
        { name: "Une source unique", link: "/#uniciteSection5" },
        { name: "Une histoire unique", link: "/#uniciteSection6" },
      ],
      c2Heading: "Bienfaits sur le corps",
      c2Items: [
        { name: "La récupération", link: "/benefits/#BenefitSection1" },
        { name: "Témoignages", link: "/benefits/#BenefitSection2" },
      ],
    };
  },
  mounted() {
    this.c1Heading = this.$i18n.t("menu_1");
    (this.c1Items = [
      { name: this.$i18n.t("menu_1_sub_1"), link: "/#uniciteSection2" },
      { name: this.$i18n.t("menu_1_sub_2"), link: "/#uniciteSection3" },
      { name: this.$i18n.t("menu_1_sub_3"), link: "/#uniciteSection4" },
      { name: this.$i18n.t("menu_1_sub_4"), link: "/#uniciteSection5" },
      { name: this.$i18n.t("menu_1_sub_5"), link: "/#uniciteSection6" },
    ]),
      (this.c2Heading = this.$i18n.t("menu_2")),
      (this.c2Items = [
        {
          name: this.$i18n.t("menu_2_sub_1"),
          link: "/benefits/#BenefitSection1",
        },
        {
          name: this.$i18n.t("menu_2_sub_2"),
          link: "/benefits/#BenefitSection2",
        },
      ]);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
