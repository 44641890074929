<template>
  <div
    class="footer__section3 footer__socialsection position-relative"
    :class="locale"
  >
    <div
      class="d-flex justify-content-xl-center justify-content-end footer__socialsection__subcontainer"
      :class="locale"
    >
      <a href="/boutique/#/" target="_blank">
        <button
          class="footer__button d-flex align-items-center"
          :class="locale"
        >
          <span class="footer__button__text pr-2" :class="locale">{{
            $t("menu_5")
          }}</span>
          <Box boxcolor="white" />
        </button>
      </a>
    </div>
    <div
      class="
        d-flex
        justify-content-xl-center justify-content-end
        mt-4
        footer__section3__social
      "
      :class="locale"
    >
      <div class="d-flex flex-column">
        <p class="footer__socialtext" :class="locale">{{ $t("social") }}</p>
        <div class="d-flex align-items-center mt-1">
          <div class="footer__fb" :class="locale">
            <a href="https://www.facebook.com/EAUSIDIHARAZEM" target="_blank"
              ><img :src="fb" alt="Facebook" />
            </a>
          </div>
          <div class="ml-auto footer__insta" :class="locale">
            <a
              href="https://www.instagram.com/sidiharazemofficiel/"
              target="_blank"
              ><img :src="insta" alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        footer__space
        d-flex
        justify-content-xl-center justify-content-end
        footer__logos
      "
      :class="locale"
    >
      <div v-if="locale == 'fr'">
        <a href="/#section1"><img :src="logo" alt="logo"/></a>
        <a href="/#section1"
          ><img class="ml-2" :src="source" alt="source logo"
        /></a>
      </div>
      <div v-else-if="locale == 'ar'">
        <a href="/#section1"><img :src="logo" alt="logo"/></a>
        <a href="/#section1"
          ><img class="mr-2" :src="source2" alt="source logo"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import Box from "../svgs/headerbox.vue";

export default {
  data() {
    return {
      logo: require("../../assets/svgs/footerlogo.svg"),
      source: require("../../assets/svgs/sourceicon-footer.svg"),
      source2: require("../../assets/images/common/footer_logo_ar.png"),
      fb: require("../../assets/svgs/fb.svg"),
      insta: require("../../assets/svgs/insta.svg"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  components: {
    Box,
  },
};
</script>

<style lang="scss" scoped></style>
