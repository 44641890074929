<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="21"
    viewBox="0 0 29 21"
  >
    <g id="Group_1265" data-name="Group 1265" transform="translate(-29 -28)">
      <line
        id="Line_25"
        data-name="Line 25"
        x2="26"
        transform="translate(30.5 29.5)"
        fill="none"
        :stroke="color || '#283583'"
        stroke-linecap="round"
        stroke-width="3"
      />
      <line
        id="Line_26"
        data-name="Line 26"
        x2="16"
        transform="translate(30.5 38.5)"
        fill="none"
        :stroke="color || '#283583'"
        stroke-linecap="round"
        stroke-width="3"
      />
      <line
        id="Line_27"
        data-name="Line 27"
        x2="26"
        transform="translate(30.5 47.5)"
        fill="none"
        :stroke="color || '#283583'"
        stroke-linecap="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>

<script>
export default { props: ["color"] };
</script>

<style lang="scss" scoped></style>
