<template>
  <div class="header__mobile__menu" :class="locale">
    <button
      @click="$emit('onClick')"
      class="btn p-0 header__mobile__menu__close"
    >
      <img :src="cross" alt="" />
    </button>
    <div
      class="header__mobile__menu__linkContainer text-center mb-2"
      :class="locale"
      v-for="(link, index) in linkData"
      :key="index"
    >
      <!-- link heading -->
      <router-link :to="link.to"
        ><span class="header__mobile__menu__heading">{{
          link.heading
        }}</span></router-link
      >
      <!-- link subheadings -->
      <ul class="m-0 p-0">
        <li class="my-1" v-for="(links, i) in link.subheadings" :key="i">
          <router-link :to="link.to"
            ><span class="header__mobile__menu__subheading">{{
              links
            }}</span></router-link
          >
        </li>
      </ul>
    </div>

    <!-- button -->
    <div class="d-flex justify-content-center my-3">
      <a href="/boutique/#/" target="_blank">
        <button
          class="
            text-center
            header__mobile__menu__button
            d-flex
            align-items-center
          "
          :class="locale"
        >
          <span class="header__mobile__menu__button__text pr-2">{{
            $t("menu_5")
          }}</span>
          <Box boxcolor="red" />
        </button>
      </a>
    </div>

    <!-- langs -->
    <!-- <div class="d-flex justify-content-center align-items-center">
      <span class="header__mobile__menu__lang" @click="changeLange('fr')"
        >FR</span
      >
      <span class="header__mobile__menu__lang ml-2" @click="changeLang('ar')"
        >AR</span
      >
    </div> -->
  </div>
</template>

<script>
import Box from "../svgs/headerbox.vue";

export default {
  props: ["locale"],
  components: { Box },

  data() {
    return {
      cross: require("../../assets/images/home/common/cross-blue.webp"),
      linkData: [],
    };
  },
  mounted() {
    this.linkData = [
      {
        heading: this.$i18n.t("menu_1"),
        to: "/",
      },
      {
        heading: this.$i18n.t("menu_2"),
        to: "/benefits",
      },
      {
        heading: this.$i18n.t("menu_3"),
        to: "/marque",
      },
      {
        to: "/formulairecontact",
        heading: this.$i18n.t("menu_4"),
      },
      {
        to: "/formulairerh",
        heading: this.$i18n.t("form_rh_menu"),
      },
    ];
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      location.reload();
    },
  },
  watch: {
    "$i18n.locale"(value) {
      this.linkData = [
        {
          heading: this.$i18n.t("menu_1"),
          to: "/",
        },
        {
          heading: this.$i18n.t("menu_2"),
          to: "/benefits",
        },
        {
          heading: this.$i18n.t("menu_3"),
          to: "/marque",
        },
        {
          to: "/formulairecontact",
          heading: this.$i18n.t("menu_4"),
        },
        {
          to: "/formulairerh",
          heading: this.$i18n.t("form_rh_menu"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
