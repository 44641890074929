<template>
  <div class="footer__section footersection1">
    <div class="row mx-0">
      <div class="col-4 px-0">
        <SectionContent :heading="c1Heading" :listitems="c1Items" />
      </div>
      <!-- <div class="col-4 px-0">
        <SectionContent :heading="c2Heading" :listitems="c2Items" />
      </div> -->
      <div class="col-4 px-0">
        <a href="/formulairecontact" target="_blank">
          <h1 class="footer__sectionContent__heading hover">
            {{ $t("menu_4") }}
          </h1>
        </a>
        <a href="/formulairerh" target="_blank">
          <h1 class="footer__sectionContent__heading hover">
            {{ $t("form_rh_menu") }}
          </h1>
        </a>
      </div>
      <div class="col-12 px-0">
        <Contact />
      </div>
    </div>
  </div>
</template>

<script>
import SectionContent from "./SectionContent.vue";
import Contact from "./Contact.vue";
export default {
  components: {
    SectionContent,
    Contact,
  },
  data() {
    return {
      c1Heading: "",
      c1Items: [],
      // c2Heading: "Rejoignez Nous",
      // c2Items: [
      //   { name: "Partagez votre avis", link: "/contacter" },
      //   { name: "Rejoignez Nous", link: "/rejoindre" },
      // ],
    };
  },
  mounted() {
    this.c1Heading = this.$i18n.t("menu_3");
    this.c1Items = [
      { name: this.$i18n.t("menu_3_sub_1"), link: "/marque/#marqueSection1" },
      { name: this.$i18n.t("menu_3_sub_2"), link: "/marque/#marqueSection2" },
      { name: this.$i18n.t("menu_3_sub_3"), link: "/marque/#marqueSection3" },
    ];
  },
};
</script>

<style lang="scss" scoped>
.hover:hover {
  color: red;
}
</style>
