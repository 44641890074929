<template>
  <div class="footer__sectionContent">
    <h1
      class="footer__sectionContent__heading d-flex align-items-center"
      :class="locale"
    >
      <span :class="dotClass"></span>
      <span>{{ heading }}</span>
    </h1>
    <ul>
      <li
        class="d-flex footer__sectionContent__item"
        v-for="(item, i) in listitems"
        :key="i"
        :class="locale"
      >
        <a :href="item.link">
          <span :class="locale" class=" footer__sectionContent__item__text">{{
            item.name
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["heading", "listitems", "dotClass"],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
