<template>
  <div @click="routeNavigation">
    <li class="header__linkbox" :class="locale">
      <div class="d-flex align-items-center header__linkbox__dotTextContainer">
        <span class="header__linkdot" v-if="locale == 'fr'"></span>
        <span class="header__link" :class="locale">{{ linktext }}</span>
        <span class="header__linkdot ar" v-if="locale == 'ar'"></span>
      </div>
      <!-- sublinks box -->
      <div class="header__link__sublinks" :class="externalclass">
        <div
          @click="linkClicked(sublink)"
          class=" d-flex align-items-center  header__link__sublinks__box mb-1"
          :class="{ 'justify-content-end': locale == 'ar' }"
          v-for="(sublink, i) in sublinksArray"
          :key="i"
        >
          <span
            :to="{ name: `${sublink.to}`, hash: `${sublink.hash}` }"
            class="m-0 header__link__sublinks__link"
            :class="locale"
            >{{ sublink.name }}</span
          >
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  props: ["linktext", "sublinksArray", "path", "externalclass"],
  computed: {
    pathname() {
      return `${this.path}`;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    linkClicked(sublink) {
      this.$emit("onclick", sublink);
    },
    routeNavigation() {
      if (this.$route.fullPath == this.path) {
        return;
      } else {
        this.$router.push(this.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
