<template>
  <div
    class="
      footer__mobile
      d-flex
      flex-column
      align-items-center
      justify-content-center
      d-md-none
    "
    :class="locale"
  >
    <a href="/boutique/#/" target="_blank">
      <button
        :class="locale"
        class="footer__mobile__button d-block text-center mb-2"
      >
        <span :class="locale" class="footer__mobile__button__text">{{
          $t("menu_5")
        }}</span>
      </button>
    </a>
    <!-- contact -->
    <div
      class="footer__contact d-flex flex-column text-center my-2"
      :class="locale"
    >
      <div
        class="
          d-flex
          align-items-center
          mb-2
          text-center
          justify-content-center
          flex-column
        "
        :class="locale"
      >
        <img
          class="footer__contacticons location"
          :class="locale"
          :src="location"
          alt="map"
        />
        <p class="footer__contact__text mt-2" :class="locale">
          {{ $t("add") }}
        </p>
      </div>
      <div
        class="
          d-flex
          align-items-center
          mb-2
          text-center
          justify-content-center
          flex-column
        "
        :class="locale"
      >
        <img
          class="footer__contacticons"
          :class="locale"
          :src="whatsapp"
          alt="whatsapp"
        />
        <p class="footer__contact__text mt-2" :class="locale">06 08 051 051</p>
      </div>
      <div
        class="
          d-flex
          align-items-center
          text-center
          justify-content-center
          flex-column
        "
        :class="locale"
      >
        <img class="footer__contacticons" :src="email" alt="email" />
        <p class="footer__contact__text mt-2" :class="locale">
          contact@sidiharazem.ma
        </p>
      </div>
    </div>
    <!-- logos -->
    <div
      class="
        footer__space
        d-flex
        justify-content-xl-center justify-content-end
        footer__logos
      "
      :class="locale"
    >
      <div>
        <a href="/#section1"><img :src="logo" alt="logo"/></a>
        <a href="/#section1"
          ><img class="ml-2" :src="source" alt="source logo"
        /></a>
      </div>
    </div>
    <!-- copyright -->
    <p
      class="footer__copyright d-block d-lg-none text-center w-100"
      :class="locale"
    >
      {{ $t("Copyrights.text1") }}
      <span class="text-secondary ml-1">{{ $t("Copyrights.text2") }}</span
      >{{ $t("Copyrights.text3") }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: require("../../assets/svgs/email.svg"),
      location: require("../../assets/svgs/location.svg"),
      whatsapp: require("../../assets/svgs/whatsapp.svg"),
      logo: require("../../assets/svgs/footerlogo.svg"),
      source: require("../../assets/svgs/sourceicon-footer.svg"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
