<template>
  <div class="footer__space footer__contact d-flex flex-column" :class="locale">
    <div class="d-flex align-items-center mb-lg-2 mb-4">
      <img
        class="footer__contacticons location"
        :class="locale"
        :src="location"
        alt="map"
      />
      <p class="footer__contact__text" :class="locale">
        {{ $t("add") }}
      </p>
    </div>
    <div class="d-flex align-items-center mb-lg-2 mb-4">
      <img
        class="footer__contacticons"
        :class="locale"
        :src="whatsapp"
        alt="whatsapp"
      />
      <p class="footer__contact__text" :class="locale">
        06 08 051 051
      </p>
    </div>
    <div class="d-flex align-items-center ">
      <img
        class="footer__contacticons"
        :class="locale"
        :src="email"
        alt="email"
      />
      <p class="footer__contact__text" :class="locale">
        contact@sidiharazem.ma
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: require("../../assets/svgs/email.svg"),
      location: require("../../assets/svgs/location.svg"),
      whatsapp: require("../../assets/svgs/whatsapp.svg"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
