<template>
  <svg
    id="Group_1073"
    data-name="Group 1073"
    xmlns="http://www.w3.org/2000/svg"
    width="23.745"
    height="18.475"
    viewBox="0 0 23.745 18.475"
  >
    <path
      id="Path_36"
      data-name="Path 36"
      d="M23.745,2.844a.228.228,0,0,0-.018-.108V2.7l-.054-.108V2.575a.392.392,0,0,0-.072-.09h0a.376.376,0,0,0-.108-.072h-.018l-.108-.054h-.054L8.923.2h-.2c-.018,0-.054.018-.072.018s-.018,0-.036.018L.342,4.014H.324l-.018.018c-.018.018-.054.036-.072.054A.018.018,0,0,0,.216,4.1h0l-.072.072v.018c-.018.018-.036.054-.054.072,0,.018,0,.018-.018.036s-.018.054-.036.072v.018C0,4.409,0,4.427,0,4.463H0V15.077a.523.523,0,0,0,.432.522l15.65,3.058c.036,0,.072.018.108.018H16.3a.066.066,0,0,0,.054-.018c.018,0,.018,0,.036-.018.018,0,.036-.018.054-.018s.018-.018.036-.018a.018.018,0,0,0,.018-.018l7.016-4.677a.563.563,0,0,0,.234-.45V2.844ZM16.082,6.6l-6.314-1.1H9.732l-.378-.054V1.333l12.3,1.853ZM8.275,1.549V5.273L3.8,4.5,2.357,4.248Zm-7.2,3.562L15.65,7.629v9.858L1.079,14.645Zm21.587,8.077L16.73,17.146V7.486l4.281-2.644,1.655-1.025Z"
      transform="translate(0 -0.2)"
      :fill="boxcolor || '#00A5D1'"
    />
    <path
      id="Path_37"
      data-name="Path 37"
      d="M97.6,41.144a.471.471,0,0,0,.4.222.4.4,0,0,0,.253-.079l3.008-1.9a.47.47,0,1,0-.507-.791l-3.008,1.9A.462.462,0,0,0,97.6,41.144Z"
      transform="translate(-80.041 -31.353)"
      :fill="boxcolor || '#00A5D1'"
    />
    <path
      id="Path_38"
      data-name="Path 38"
      d="M21.533,44.283l-9.444-1.828a.457.457,0,1,0-.168.9l9.444,1.828c.03,0,.061.015.091.015a.443.443,0,0,0,.442-.366A.479.479,0,0,0,21.533,44.283Z"
      transform="translate(-8.772 -34.363)"
      :fill="boxcolor || '#00A5D1'"
    />
  </svg>
</template>

<script>
export default {
  props: ["boxcolor"],
};
</script>

<style lang="scss" scoped></style>
