<template>
  <div class="headerContainer">
    <div class="header fr" v-if="locale == 'fr'">
      <router-link to="/" class="header__logo" :class="locale">
        <img :src="logo" alt="header logo" />
      </router-link>
      <!-- desktop header -->
      <div class="container h-100 d-none d-lg-flex">
        <ul class="d-flex header__linksContainer align-items-center">
          <HeaderLink
            path="/"
            :linktext="$t('menu_1')"
            :sublinksArray="link1Array"
            @onclick="goto"
          />

          <HeaderLink
            :linktext="$t('menu_2')"
            :sublinksArray="link2Array"
            path="/benefits"
            @onclick="goto"
          />

          <HeaderLink
            path="/marque"
            :linktext="$t('menu_3')"
            :sublinksArray="link3Array"
            @onclick="goto"
          />
        </ul>

        <div class="d-flex align-items-center ml-auto">
          <ul class="d-flex header__linksContainer align-items-center pl-0">
            <!-- <HeaderLink linktext="Actualité" :sublinksArray="link4Array" /> -->

            <HeaderLink path="/formulairecontact" :linktext="$t('menu_4')" />
          </ul>
          <!-- <router-link :to="'/boutique'"> -->
          <a href="/boutique/#/" target="_blank">
            <button class="header__button d-flex align-items-center">
              <span class="header__button__text pr-2">{{ $t("menu_5") }}</span>
              <Box />
            </button>
          </a>
          <!-- </router-link> -->
          <button class="btn p-0" @click="changeLocale">
            <span class="header__lang">{{ $t("unicite.ID") }}</span>
          </button>
        </div>
      </div>
      <!-- mobile header-->
      <div class="header__mobileContainer d-flex d-lg-none">
        <button @click="showMenu = true" class="btn header__mobile__btn p-0">
          <MenuIcon />
        </button>
        <div class="d-flex align-items-center">
          <button class="btn p-0" @click="changeLocale">
            <span class="header__lang mobile" :class="locale">{{
              $t("unicite.ID")
            }}</span>
          </button>
          <a href="/boutique/#/" target="_blank">
            <Box />
          </a>
        </div>
      </div>

      <!-- mobile menu -->
      <div
        class="header__mobile__menuContainer d-block d-lg-none"
        :class="{ show: showMenu }"
        v-click-outside="onClickOutside"
      >
        <MobileMenuContent @onClick="showMenu = false" />

        <div class="header__mobile__menu__bottom">
          <div class="header__mobile__menu__logo" :class="locale">
            <img :src="logo" alt="header logo" />
          </div>
        </div>
      </div>
    </div>

    <div class="header ar" v-else>
      <router-link to="/" class="header__logo" :class="locale">
        <img :src="logo2" alt="header logo" />
      </router-link>
      <!-- desktop header -->
      <div class="container h-100 d-none d-lg-flex">
        <div class="d-flex align-items-center mr-auto">
          <button class="btn p-0" @click="changeLocale">
            <span class="header__lang">{{ $t("unicite.ID") }}</span>
          </button>
          <a href="/boutique/#/" target="_blank">
            <button class="header__button ar d-flex align-items-center">
              <span :class="locale" class="header__button__text pr-2">{{
                $t("menu_5")
              }}</span>
              <Box />
            </button>
          </a>

          <ul class="d-flex header__linksContainer align-items-center pl-0">
            <HeaderLink
              locale="ar"
              path="/formulairecontact"
              :linktext="$t('menu_4')"
            />
          </ul>
        </div>
        <ul class="d-flex header__linksContainer align-items-center">
          <HeaderLink
            path="/marque"
            :linktext="$t('menu_3')"
            :sublinksArray="link3Array"
            @onclick="goto"
            externalclass="ar ar3"
            locale="ar"
          />

          <HeaderLink
            :linktext="$t('menu_2')"
            :sublinksArray="link2Array"
            path="/benefits"
            @onclick="goto"
            externalclass="ar ar2"
            locale="ar"
          />
          <HeaderLink
            path="/"
            :linktext="$t('menu_1')"
            :sublinksArray="link1Array"
            @onclick="goto"
            externalclass="ar ar1"
            locale="ar"
          />
        </ul>
      </div>
      <!-- mobile header-->
      <div class="header__mobileContainer d-flex d-lg-none">
        <button @click="showMenu = true" class="btn header__mobile__btn p-0">
          <MenuIcon />
        </button>
        <div class="d-flex align-items-center">
          <button class="btn p-0" @click="changeLocale">
            <span class="header__lang mobile" :class="locale">{{
              $t("unicite.ID")
            }}</span>
          </button>
          <a href="/boutique/#/" target="_blank">
            <Box />
          </a>
        </div>
      </div>

      <!-- mobile menu -->
      <div
        class="header__mobile__menuContainer d-block d-lg-none"
        :class="{ show: showMenu }"
        v-click-outside="onClickOutside"
      >
        <MobileMenuContent locale="ar" @onClick="showMenu = false" />

        <div class="header__mobile__menu__bottom">
          <div class="header__mobile__menu__logo" :class="locale">
            <img :src="logo2" alt="header logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLink from "./header/Link.vue";
import Box from "./svgs/headerbox.vue";
import MenuIcon from "./svgs/Menu.vue";
import MobileMenuContent from "./header/MobileMenuContent.vue";
import vClickOutside from "v-click-outside";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    HeaderLink,
    Box,
    MenuIcon,
    MobileMenuContent,
  },
  data() {
    return {
      showMenu: false,
      logo: require("../assets/svgs/logo.svg"),
      logo2: require("../assets/images/common/header-logo.svg"),
      // logo2: require("../assets/images/common/header-logo.png"),
      link1Array: [],
      link2Array: [],
      link3Array: [],
      // link4Array: [
      //   { name: "Campagnes", link: "/#section1" },
      //   { name: "Promotions", link: "/#section1" },
      // ],
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.link1Array = [
      {
        name: this.$i18n.t("menu_1_sub_1"),
        to: "Home",
        hash: "#uniciteSection2",
      },
      {
        name: this.$i18n.t("menu_1_sub_2"),
        to: "Home",
        hash: "#uniciteSection3",
      },
      {
        name: this.$i18n.t("menu_1_sub_3"),
        to: "Home",
        hash: "#uniciteSection4",
      },
      {
        name: this.$i18n.t("menu_1_sub_4"),
        to: "Home",
        hash: "#uniciteSection5",
      },
      {
        name: this.$i18n.t("menu_1_sub_5"),
        to: "Home",
        hash: "#uniciteSection6",
      },
    ];
    this.link2Array = [
      {
        name: this.$i18n.t("menu_2_sub_1"),
        to: "Benefits",
        hash: "#BenefitSection1",
      },
      {
        name: this.$i18n.t("menu_2_sub_2"),
        to: "Benefits",
        hash: "#BenefitSection2",
      },
    ];
    this.link3Array = [
      ({
        name: this.$i18n.t("menu_3_sub_1"),
        to: "Marque",
        hash: "#marqueSection1",
      },
      {
        name: this.$i18n.t("menu_3_sub_2"),
        to: "Marque",
        hash: "#marqueSection2",
      },
      {
        name: this.$i18n.t("menu_3_sub_3"),
        to: "Marque",
        hash: "#marqueSection3",
      }),
    ];
  },
  watch: {
    "$i18n.locale"(value) {
      this.link1Array = [
        {
          name: this.$i18n.t("menu_1_sub_1"),
          to: "Home",
          hash: "#uniciteSection2",
        },
        {
          name: this.$i18n.t("menu_1_sub_2"),
          to: "Home",
          hash: "#uniciteSection3",
        },
        {
          name: this.$i18n.t("menu_1_sub_3"),
          to: "Home",
          hash: "#uniciteSection4",
        },
        {
          name: this.$i18n.t("menu_1_sub_4"),
          to: "Home",
          hash: "#uniciteSection5",
        },
        {
          name: this.$i18n.t("menu_1_sub_5"),
          to: "Home",
          hash: "#uniciteSection6",
        },
      ];
      this.link2Array = [
        {
          name: this.$i18n.t("menu_2_sub_1"),
          to: "Benefits",
          hash: "#BenefitSection1",
        },
        {
          name: this.$i18n.t("menu_2_sub_2"),
          to: "Benefits",
          hash: "#BenefitSection2",
        },
      ];
      this.link3Array = [
        ({
          name: this.$i18n.t("menu_3_sub_1"),
          to: "Marque",
          hash: "#marqueSection1",
        },
        {
          name: this.$i18n.t("menu_3_sub_2"),
          to: "Marque",
          hash: "#marqueSection2",
        },
        {
          name: this.$i18n.t("menu_3_sub_3"),
          to: "Marque",
          hash: "#marqueSection3",
        }),
      ];
    },
  },
  methods: {
    goto(linkdata) {
      if (this.$route.name === linkdata.to) {
        this.$scrollTo(linkdata.hash, 500);
      } else {
        this.$router.push({ name: linkdata.to, hash: linkdata.hash });
      }
    },
    onClickOutside(e) {
      let menubtn = document.querySelector(".header__mobile__btn");

      if (menubtn) {
        if (
          !menubtn.contains(e.target) &&
          this.showMenu &&
          e.target !== menubtn
        ) {
          this.showMenu = false;
        }
      }
    },
    changeLocale() {
      if (this.$i18n.locale == "fr") {
        this.$i18n.locale = "ar";
      } else if (this.$i18n.locale == "ar") {
        this.$i18n.locale = "fr";
      }

      localStorage.setItem("lang", this.$i18n.locale);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped></style>
